// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '../stylesheets/application'
import './bootstrap_custom.js'

import ClipboardJS from 'clipboard'
require('@rails/ujs').start()

$(document).on('click', '.btn:not(.clipboard-btn)', function () {
  var btn = $(this)
  btn.width(btn.width())
  btn.html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>')
})

$(document).ready(function () {
  var clipboard = new ClipboardJS('.clipboard-btn')
  clipboard.on('success', function (e) {
    $(e.trigger).removeClass('btn-outline-primary')
    $(e.trigger).addClass('btn-outline-success')
    $(e.trigger).text('Copied!')
  })
})
